import styled from '@emotion/styled';
import { FONT } from '@zola/zola-ui/src/styles/emotion';
import { COLORS_BACKGROUND, COLORS_TEXT } from '@zola/zola-ui/src/styles/emotion/colors';

export const Container = styled.div`
  border: 2px solid ${COLORS_BACKGROUND.OUTLINE_GRAY};
  border-radius: 26px;
  color: ${COLORS_TEXT.SECONDARY};
  display: flex;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 16px;
  padding-right: 6px;
  font-size: ${FONT.FONT_SIZE_SMALL};
  font-weight: 600;
  background-color: ${COLORS_BACKGROUND.WHITE};
`;

export const CloseIconContainer = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
`;
