export const SORT_OPTIONS = [
  {
    value: 'FEATURED',
    label: 'Featured',
  },
  {
    value: 'PRICE_ASC',
    label: 'Price: Low to High',
  },
  {
    value: 'PRICE_DESC',
    label: 'Price: High to Low',
  },
];

export const DEFAULT_COLLECTION_OPTION = { value: 'ALL_COLLECTIONS', label: `All Categories` };

export const COLLECTIONS_DROPDOWN_FACET = { key: 'CATEGORIES', label: 'Categories' };

export const SORT_DROPDOWN_FACET = { key: 'SORT_BY', label: 'Sort by' };
