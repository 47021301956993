import styled from '@emotion/styled';
import { FONT, MEDIA_QUERY, SPACING } from '@zola/zola-ui/src/styles/emotion';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: ${SPACING.MD} 0;

  a {
    align-items: center;
    display: flex;
    flex-flow: row;
    height: 44px;
    justify-content: center;
    width: 200px;

    &:hover {
      text-decoration: none;
    }
  }

  ${MEDIA_QUERY.MOBILE} {
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
  }
`;

export const TextContainer = styled.div`
  width: calc(100% - 200px);

  > * {
    font-size: 20px;
    letter-spacing: 1;
    line-height: 1.6;
    margin: 0;
    text-align: left;

    ${MEDIA_QUERY.MOBILE} {
      text-align: center;
    }
  }

  h5 {
    letter-spacing: normal;
  }

  ${MEDIA_QUERY.MOBILE} {
    margin-bottom: ${SPACING.S24};
  }
`;

export const ContainerSP = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 48px;
  max-width: 100%;
  width: 100%;

  button {
    width: 200px;
  }
`;

export const TextContainerSP = styled.div`
  margin-bottom: 30px;

  h5 {
    ${FONT.textSizeRegular(FONT.SIZE.REGULAR)}
    letter-spacing: normal;
    text-align: center;
  }
`;
