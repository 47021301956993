import React from 'react';

// types
import type { WCmsWeddingRegistryView } from '@zola/svc-web-api-ts-client';

// context
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';

// styles
import { Container, TextContainer, ContainerSP, TextContainerSP } from './ExternalRegistry.styles';

export type ExternalRegistryProps = Pick<
  WCmsWeddingRegistryView,
  'name' | 'description' | 'url'
> & {
  id?: string;
  isSinglePage?: boolean;
};

const ExternalRegistry: React.FC<ExternalRegistryProps> = ({
  name,
  description,
  url,
  id,
  isSinglePage,
}) => {
  const {
    state: {
      components: { cmsEntityComponentBodyFontValues, ThemedButton },
      inPreview,
    },
  } = useWebsiteThemeContext();

  const handleClickBtn = () => {
    if (inPreview) return;
    if (typeof window !== 'undefined') {
      // DO NOT REMOVE - Required for affiliate tracking
      try {
        window.vglnk.open(url, '_blank');
      } catch (err) {
        window.open(url, '_blank', 'noopener, noreferrer')?.focus();
      }
    }
  };

  const textStyles = {
    fontFamily: cmsEntityComponentBodyFontValues.fontFamily,
    color: `#${cmsEntityComponentBodyFontValues.color}`,
  };

  return isSinglePage ? (
    <ContainerSP id={id}>
      <TextContainerSP style={textStyles}>
        <h5 style={textStyles}>{name}</h5>
        <div>{description}</div>
      </TextContainerSP>
      <ThemedButton onClick={handleClickBtn}>View</ThemedButton>
    </ContainerSP>
  ) : (
    <Container id={id} style={{ borderBottomColor: `#${cmsEntityComponentBodyFontValues.color}` }}>
      <TextContainer style={textStyles}>
        <h5 style={textStyles}>{name}</h5>
        <div>{description}</div>
      </TextContainer>
      <ThemedButton onClick={handleClickBtn}>See registry</ThemedButton>
    </Container>
  );
};

export default ExternalRegistry;
