import styled from '@emotion/styled';
import { Input } from '@zola/zola-ui/src/components/Form/dropdownV3/DropdownV3.styles';
import { SPACING } from '@zola/zola-ui/src/styles/emotion';
import { GenericFinalFormDropdown } from 'components/publicWebsiteV2/util/genericFormElements.styles';

export const FacetRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${SPACING.SM};
`;

export const FilterGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 760px;

  > * {
    margin-right: ${SPACING.SM};
    margin-bottom: ${SPACING.XS};
  }
`;

export const CategoriesDropdown = styled(GenericFinalFormDropdown)`
  width: 155px;
`;

export const SortDropdown = styled(GenericFinalFormDropdown)`
  ${Input} {
    width: 180px;
  }
`;

export const StyledGenericFinalFormDropdown = styled(GenericFinalFormDropdown)`
  ${Input} {
    width: 160px;
  }
`;
