import styled from '@emotion/styled';
import { SPACING } from '@zola/zola-ui/src/styles/emotion';
import { Container } from './FilterPill/FilterPill.styles';

export const PillContainer = styled.div`
  display: flex;
  margin-top: ${SPACING.XS};
  align-items: center;
  ${Container} {
    margin-right: ${SPACING.SM};
  }
`;
