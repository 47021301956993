import React, { useState, useRef } from 'react';
import dynamic from 'next/dynamic';
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';
import styled from '@emotion/styled';
import type { WAwsFacet, WRegistryCollectionItemView } from '@zola/svc-web-api-ts-client';
import Modal from '@zola/zola-ui/src/components/Modal/Modal';
import { mapValuesToButtonStyles } from 'components/publicWebsiteV2/util/mappers/mapButtonStyles';
import { isDarkColor } from 'pages/publicStyleUtils/utils.styles';
import { mapValuesToMobileFilterOverrides } from './ZolaRegistryFilterBarMobile.styles';
import {
  SORT_OPTIONS,
  COLLECTIONS_DROPDOWN_FACET,
  SORT_DROPDOWN_FACET,
  DEFAULT_COLLECTION_OPTION,
} from '../helpers';

const MobileFilters = dynamic(
  () => import('@zola/component-public-registry').then(module => module.MobileFilters),
  {
    loading: () => <div>loading</div>,
    ssr: false,
  }
);

const FilterButton = dynamic(
  () => import('@zola/component-public-registry').then(module => module.FilterButton),
  {
    loading: () => <div>loading</div>,
    ssr: false,
  }
);

type ZolaRegistryFilterBarMobileProps = {
  collection: WRegistryCollectionItemView[];
  collectionOptions: { value: string | undefined; label: string | undefined }[];
  selectedCollectionObject:
    | { value: string | undefined; label: string | undefined }
    | null
    | undefined;
  onSelectCollection: (c: string) => void;
  onUpdateSort: (v: string) => void;
  sort: string;
  onResetFacets: () => void;
  facets: WAwsFacet[];
  onToggleFacet: (k: string, v: string) => void;
  selectedFacetValues: { [k: string]: string[] };
};

const ZolaRegistryFilterBarMobile = ({
  collection,
  collectionOptions,
  selectedCollectionObject,
  onSelectCollection,
  onUpdateSort,
  sort,
  onResetFacets,
  facets,
  onToggleFacet,
  selectedFacetValues,
}: ZolaRegistryFilterBarMobileProps): JSX.Element => {
  const {
    state: {
      components: { cmsEntityComponentBodyFontValues, globalAccentColor },
    },
  } = useWebsiteThemeContext();
  const [showMobileFilterModal, setShowMobileFilterModal] = useState(false);
  const triggerRef = useRef<HTMLDivElement>(null);
  const StyledMobileFilter = styled(MobileFilters)`
    ${mapValuesToMobileFilterOverrides(
      cmsEntityComponentBodyFontValues.fontFamily,
      globalAccentColor
    )}
  `;

  // TODO: replace filter button image with icon from zola-ui
  const isAccentColorDark = isDarkColor(globalAccentColor);
  const fontColor = isAccentColorDark ? '#FFFFFF' : '#000000';
  const StyledFilterButton = styled(FilterButton)`
    .public-registry-facets__button {
      ${mapValuesToButtonStyles(cmsEntityComponentBodyFontValues.fontFamily, globalAccentColor)}
      max-width: 175px;
      .public-registry-facets__text {
        color: ${fontColor};
      }
    }
  `;

  return (
    <>
      <div ref={triggerRef}>
        <StyledFilterButton
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ onClick: () => void; productTotal: number;...
          onClick={() => {
            setShowMobileFilterModal(true);
          }}
          productTotal={collection.length}
          filterMainTitle="Filter & Sort"
        />
      </div>
      {showMobileFilterModal && (
        <Modal
          triggerRef={triggerRef}
          rootId="wedding-modal-root"
          onClose={() => setShowMobileFilterModal(false)}
          dialogHeadingId="public-website-registry-facet-modal"
        >
          <StyledMobileFilter
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ onToggleMobileFilters: () => void; onSort:...
            onToggleMobileFilters={() => {
              setShowMobileFilterModal(false);
            }}
            onSort={(o: { value: string; label: string }) => {
              onUpdateSort(o.value);
            }}
            selectedSortValue={sort}
            productTotal={collection.length}
            onClearAll={() => {
              onSelectCollection(DEFAULT_COLLECTION_OPTION.value);
              onResetFacets();
            }}
            facets={[
              {
                key: COLLECTIONS_DROPDOWN_FACET.key,
                label: COLLECTIONS_DROPDOWN_FACET.label,
                options: collectionOptions,
              },
              ...facets,
              {
                key: SORT_DROPDOWN_FACET.key,
                label: SORT_DROPDOWN_FACET.label,
                options: SORT_OPTIONS,
              },
            ]}
            filterSelect={onToggleFacet}
            selectedFilters={selectedFacetValues}
            onHandleDropdownSelection={onToggleFacet}
            radioButtonsElements={[COLLECTIONS_DROPDOWN_FACET.key, SORT_DROPDOWN_FACET.key]}
            filterMainTitle="Filter & Sort"
            onSelectCategory={(o: { value: string; label: string }) => onSelectCollection(o.value)}
            enableCustomCollections={false}
            categoryId={selectedCollectionObject?.value || collectionOptions[0].value}
            mobileFilterActive
            weddingWebsite
          />
        </Modal>
      )}
    </>
  );
};

export default ZolaRegistryFilterBarMobile;
