import styled from '@emotion/styled';
import { SPACING } from '@zola/zola-ui/src/styles/emotion';

export const ExternalRegistrySection = styled.div<{ borderColor?: string }>`
  margin-top: ${SPACING.XL};
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
`;
